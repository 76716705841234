export default class Speaker {
  private audioContext!: AudioContext;
  private isInitialized = false;
  private voiceDestination!: AudioNode;
  private voices: Set<Voice> = new Set([]);

  private tryInitialization() {
    if (!this.isInitialized) {
      try {
        this.audioContext = new AudioContext();
        const compressorNode = this.audioContext.createDynamicsCompressor();
        compressorNode.connect(this.audioContext.destination);
        compressorNode.attack.setValueAtTime(0, this.audioContext.currentTime);
        this.voiceDestination = compressorNode;
        this.isInitialized = true;
      } catch (error: unknown) {
        console.error(error);
      }
    }
  }

  private getFreeVoice() {
    for (const voice of this.voices) {
      if (!voice.isPlaying()) {
        return voice;
      }
    }
    const voice = new Voice(this.audioContext, this.voiceDestination);
    this.voices.add(voice);
    return voice;
  }

  play(frequency: number, durationSeconds: number) {
    this.tryInitialization();
    if (this.isInitialized) {
      const voice = this.getFreeVoice();
      voice.play(frequency, durationSeconds);
    }
  }
}

class Voice {
  private audioContext: AudioContext;
  private gainNode: GainNode;
  private oscillatorNode: OscillatorNode;
  private stopTime = 0;

  constructor(audioContext: AudioContext, destination: AudioNode) {
    this.audioContext = audioContext;
    this.oscillatorNode = this.audioContext.createOscillator();
    this.oscillatorNode.type = "triangle";
    this.gainNode = this.audioContext.createGain();
    this.gainNode.gain.setValueAtTime(0, this.audioContext.currentTime);
    this.oscillatorNode.connect(this.gainNode);
    this.gainNode.connect(destination);
    this.oscillatorNode.start();
  }

  play(frequency: number, durationSeconds: number) {
    const currentTime = this.audioContext.currentTime;
    this.oscillatorNode.frequency.setValueAtTime(frequency, currentTime);
    this.gainNode.gain.setValueCurveAtTime(
      new Float32Array([0, 0.3, 1, 0.3, 0]),
      currentTime,
      durationSeconds
    );
    this.stopTime = currentTime + durationSeconds;
  }

  isPlaying() {
    return this.audioContext.currentTime <= this.stopTime;
  }
}

