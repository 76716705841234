import "./App.css";
import Speaker from "./Speaker";

import classNames from "classnames";
import { useDrag } from "@use-gesture/react";
import { MutableRefObject, useEffect, useRef, useState } from "react";

export default function App() {
  const speaker = new Speaker();
  const tuning = Math.floor(Math.random() * 6) - 3;
  const notes = [0, 3, 7, 10, 14, 17, 19, 21, 22, 24, 26, 27];

  const cardSpecs = notes
    .map((note) => note + tuning)
    .flatMap((note) => [note, note])
    .map((note, index) => ({
      id: index,
      value: note
    }));
  shuffle(cardSpecs);

  return (
    <Game speaker={speaker} cardSpecs={cardSpecs} />
  );
}

function shuffle<T>(array: T[]) {
  let remainingArrayLength = array.length;
  while (remainingArrayLength > 0) {
    const randomIndex = Math.floor(Math.random() * remainingArrayLength);
    const temporaryVariable = array[remainingArrayLength - 1];
    array[remainingArrayLength - 1] = array[randomIndex];
    array[randomIndex] = temporaryVariable;
    --remainingArrayLength;
  }
}

function Game({ cardSpecs, speaker }: {
  cardSpecs: CardSpec[],
  speaker: Speaker,
}) {
  const selectedRef = useRef<CardReference | null>(null);
  const matchedRef = useRef<CardReference[]>([]);
  const [failed, setFailed] = useState<CardReference[]>([]);

  const bind = useDrag(({ swipe: [swipeX, swipeY] }) => {
    if (Math.abs(swipeX) > 0 || Math.abs(swipeY) > 0) {
      for (const { setMark, setVisibility } of matchedRef.current) {
        setMark(CardMark.NONE);
        setVisibility(CardVisibility.TAKEN);
      }
      for (const { setMark, setVisibility } of failed) {
        setMark(CardMark.NONE);
        setVisibility(CardVisibility.HIDDEN);
      }
      matchedRef.current = [];
      setFailed([]); 
    }
  });

  return (
    <div {...(failed.length > 0 ? bind() : {})} className={classNames("Game", {
      failed: failed.length > 0,
    })}>
      {cardSpecs.map(({ id, value }) => <Card
        key={id}
        id={id}
        value={value}
        speaker={speaker}
        selectedRef={selectedRef}
        matchedRef={matchedRef}
        failed={failed}
        setFailed={setFailed}
      />)}
    </div>
  );
}

interface CardSpec {
  id: number,
  value: number,
}

function Card({ id, value, speaker, selectedRef, matchedRef, failed, setFailed }: {
  id: number,
  value: number,
  speaker: Speaker,
  selectedRef: MutableRefObject<CardReference | null>,
  matchedRef: MutableRefObject<CardReference[]>,
  failed: CardReference[],
  setFailed: (cardReference: CardReference[]) => void
}) {
  const [visibility, setVisibility] = useState(CardVisibility.HIDDEN);
  const [mark, setMark] = useState(CardMark.NONE);

  const bind = useDrag(({ tap }) => {
    if (tap && visibility === CardVisibility.HIDDEN) {
      setVisibility(CardVisibility.SHOWN);
      const cardReference: CardReference = {
        value,
        setVisibility,
        setMark,
      };
      if (selectedRef.current === null) {
        selectedRef.current = cardReference;
      } else {
        if (selectedRef.current.value === value) {
          selectedRef.current.setMark(CardMark.RIGHT);
          setMark(CardMark.RIGHT);
          matchedRef.current.push(
            selectedRef.current,
            cardReference
          );
        } else {
          selectedRef.current.setMark(CardMark.WRONG);
          setMark(CardMark.WRONG);
          setFailed([
            selectedRef.current,
            cardReference
          ]);
        }
        selectedRef.current = null;
      }
    }
  });

  useEffect(() => {
    if (visibility === CardVisibility.SHOWN) {
      const frequency = 440 * Math.pow(2, (value - 1) / 12);
      speaker.play(frequency, 0.4);
    }
  }, [visibility]);

  return (
    <div {...(failed.length == 0 ? bind() : {})} className={classNames("Card", {
      shown: visibility === CardVisibility.SHOWN,
      taken: visibility === CardVisibility.TAKEN,
      right: mark === CardMark.RIGHT,
      wrong: mark === CardMark.WRONG,
    })}>
      <div className="back"></div>
      <div className="front"></div>
    </div>
  );
}

enum CardVisibility {
  HIDDEN,
  SHOWN,
  TAKEN,
}

enum CardMark {
  NONE,
  RIGHT,
  WRONG,
}

interface CardReference {
  value: number;
  setVisibility: (visibility: CardVisibility) => void;
  setMark: (mark: CardMark) => void;
}
